import React from 'react'

import Services from '../../components/services/Services'




import './consultation.css'

const Consultation = () => {
  return (
    <div className='consultation'>
      <Services />
    </div>
  )
}

export default Consultation
